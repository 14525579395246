import React from "react";

export default function BambiContexte() {
	return (
		<>
			{/* <h4 className='title'>Lily Context</h4> */}
			<p className='description'>
				Lorsque’elle débarque dans la discothèque, on comprend vite que
				<span className='oliver'> Bambi </span> est une enfant très joyeuse et très dynamique...
			</p>
			<div className='video_container'>
				<iframe
					width='560'
					height='315'
					src='https://www.youtube.com/embed/vVHrUBUHvv4?si=68uuaJBKjnaaU8nf'
					title='YouTube video player'
					frameBorder='0'
					allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share'
					allowFullScreen></iframe>
			</div>
		</>
	);
}
