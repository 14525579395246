import React, { useEffect, useRef } from "react";
import "./WheelOfFortune.scss";
export default function WheelOfFortune() {
	// Textes et couleurs des secteurs
	const prizes = [
		{
			text: "Une glace au chocolat%",
			color: "hsl(197 30% 43%)",
		},
		{
			text: "Pas d'école demain !",
			color: "hsl(173 58% 39%)",
		},
		{
			text: "Poisson à la cantine",
			color: "hsl(43 74% 66%)",
		},
		{
			text: "Une barre de chocoshmoutz",
			color: "hsl(27 87% 67%)",
		},
		{
			text: "Manger une crotte de nez",
			color: "hsl(12 76% 61%)",
		},
		{
			text: "Ballade en forêt",
			color: "hsl(350 60% 52%)",
		},
	];

	// Références pour les éléments DOM
	const wheel = useRef(null);
	const spinner = useRef(null);
	const trigger = useRef(null);
	const ticker = useRef(null);

	// Nombre de secteurs de la roue
	const prizeSlice = 360 / prizes.length;
	const prizeOffset = Math.floor(180 / prizes.length);
	const spinClass = "is-spinning";
	const selectedClass = "selected";

	let tickerAnim;
	let rotation = 0;
	let currentSlice = 0;
	let prizeNodes;

	// Fonction pour créer les secteurs
	const createPrizeNodes = () => {
		prizes.forEach(({ text, color }, i) => {
			const rotation = prizeSlice * i * -1 - prizeOffset;
			spinner.current.insertAdjacentHTML(
				"beforeend",
				`<li class="prize" style="--rotate: ${rotation}deg">
						<span class="text">${text}</span>
					</li>`
			);
		});
	};

	// Fonction pour dessiner le gradient
	const createConicGradient = () => {
		spinner.current.setAttribute(
			"style",
			`background: conic-gradient(
					from -90deg,
					${prizes.map(({ color }, i) => `${color} 0 ${(100 / prizes.length) * (prizes.length - i)}%`).reverse()}
				);`
		);
	};

	// Attendre que le DOM soit prêt avant de calculer les styles
	useEffect(() => {
		// Préparer la roue une fois que les refs sont disponibles
		setupWheel();

		// Accéder aux styles de spinner après que le DOM est rendu
		const spinnerStyles = window.getComputedStyle(spinner.current);

		const runTickerAnimation = () => {
			const values = spinnerStyles.transform.split("(")[1].split(")")[0].split(",");
			const a = values[0];
			const b = values[1];
			let rad = Math.atan2(b, a);

			if (rad < 0) rad += 2 * Math.PI;

			const angle = Math.round(rad * (180 / Math.PI));
			const slice = Math.floor(angle / prizeSlice);

			if (currentSlice !== slice) {
				ticker.current.style.animation = "none";
				setTimeout(() => (ticker.current.style.animation = null), 10);
				currentSlice = slice;
			}
			tickerAnim = requestAnimationFrame(runTickerAnimation);
		};

		// Gérer le clic du bouton
		trigger.current.addEventListener("click", () => {
			trigger.current.disabled = true;
			rotation = Math.floor(Math.random() * 360 + spinertia(2000, 5000));
			prizeNodes.forEach((prize) => prize.classList.remove(selectedClass));
			wheel.current.classList.add(spinClass);
			spinner.current.style.setProperty("--rotate", rotation);
			ticker.current.style.animation = "none";
			runTickerAnimation();
		});

		// Gérer la fin de l'animation de rotation
		spinner.current.addEventListener("transitionend", () => {
			cancelAnimationFrame(tickerAnim);
			rotation %= 360;
			selectPrize();
			wheel.current.classList.remove(spinClass);
			spinner.current.style.setProperty("--rotate", rotation);
			trigger.current.disabled = false;
		});
	}, []); // Le tableau vide [] assure que cet effet s'exécute une seule fois après le montage du composant

	// Fonction pour préparer la roue
	const setupWheel = () => {
		createConicGradient();
		createPrizeNodes();
		prizeNodes = wheel.current.querySelectorAll(".prize");
	};

	// Fonction pour sélectionner le prix
	const selectPrize = () => {
		const selected = Math.floor(rotation / prizeSlice);
		prizeNodes[selected].classList.add(selectedClass);
	};

	// Fonction pour définir le nombre de tours
	const spinertia = (min, max) => {
		min = Math.ceil(min);
		max = Math.floor(max);
		return Math.floor(Math.random() * (max - min + 1)) + min;
	};

	return (
		<section className='wheel-container' id='wheel-container'>
			<div class='deal-wheel' ref={wheel}>
				<ul class='spinner' ref={spinner}></ul>

				<div class='ticker' ref={ticker}></div>

				<button class='btn-spin' ref={trigger}>
					Tente ta chance
				</button>
			</div>
		</section>
	);
}
