import React, { useContext, useRef } from "react";
import QuizzContext from "../../../../QuizzContext";
import "./Question4.scss";

export default function Question4() {
	const { checkTheInputsAnswers } = useContext(QuizzContext);
	const inputs = useRef(null);

	return (
		<div id='question4'>
			<h3>Voici un liste de ce que tu peux faire avec un téléphone…</h3>
			<h2 className='la_question'>
				coche ce que tu peux faire avec TON téléphone pendant le spectacle !
			</h2>

			<div className='answers'>
				<ul ref={inputs}>
					<li className='wa'>
						<input type='checkbox' name='agreement' />
						<label htmlFor='agreement'>Allumer ton téléphone</label>
					</li>
					<li className='ga'>
						<input type='checkbox' name='agreement' />
						<label htmlFor='agreement'>Éteindre ton téléphone</label>
					</li>
					<li className='wa'>
						<input type='checkbox' name='agreement' />
						<label htmlFor='agreement'>Téléphoner avec ton téléphone</label>
					</li>
					<li className='wa'>
						<input type='checkbox' name='agreement' />
						<label htmlFor='agreement'>
							écouter de la musique sur ton téléphone
						</label>
					</li>
					<li className='wa'>
						<input type='checkbox' name='agreement' />
						<label htmlFor='agreement'>
							commander une pizza depuis ton téléphone
						</label>
					</li>
					<li className='wa'>
						<input type='checkbox' name='agreement' />
						<label htmlFor='agreement'>
							envoyer un message depuis ton téléphone
						</label>
					</li>
					<li className='wa'>
						<input type='checkbox' name='agreement' />
						<label htmlFor='agreement'>prendre une photo avec ton téléphone</label>
					</li>
					<li className='wa'>
						<input type='checkbox' name='agreement' />
						<label htmlFor='agreement'>
							enregistrer une vidéo sur ton téléphone
						</label>
					</li>
					<li className='wa'>
						<input type='checkbox' name='agreement' />
						<label htmlFor='agreement'>
							utiliser la lampe de poche de ton téléphone
						</label>
					</li>
					<li className='wa'>
						<input type='checkbox' name='agreement' />
						<label htmlFor='agreement'>
							chasser des pokémon grâce à ton téléphone
						</label>
					</li>
					<li className='wa'>
						<input type='checkbox' name='agreement' />
						<label htmlFor='agreement'>Faire un selfie avec ton téléphone</label>
					</li>
					<li className='wa'>
						<input type='checkbox' name='agreement' />
						<label htmlFor='agreement'>
							DÉCOUPER DU SAUCISSON AVEC TON TÉLÉPHONE
						</label>
					</li>
				</ul>

				{/* <VideoModule
						webmUrl='/videos/quizz/29_anim_avant_spectacle_VP9.webm'
						mp4Url='/videos/quizz/29_anim_avant_spectacle.mp4'
						loop={true}
					/> */}
				<button className='verify cta_orangered' onClick={() => checkTheInputsAnswers(inputs)}>
					Vérifier ma réponse
				</button>
			</div>
		</div>
	);
}
