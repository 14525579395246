import React from "react";

export default function BambiInterview() {
	return (
		<div>
			<p className='description'>
				On donne la parole à Eline Schumacher
				<br />
				<span className='oliver'>« C'est qui, pour toi, Bambi ??? »</span>
			</p>
			<div className='video_container'>
				<iframe
					width='560'
					height='315'
					src='https://www.youtube.com/embed/qeRZXoaMWvU?si=vcULCiD8KtQCJc5v'
					title='YouTube video player'
					frameBorder='0'
					allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share'
					allowFullScreen></iframe>
			</div>
		</div>
	);
}
