import React from "react";
import Nav from "../components/Layout/Nav";
import "./QuizzPlay.scss";
import QuizzGame from "./PagesComponents/Quizz/QuizzGame";
import clouds from "../assets/img/intro/clouds.webp";

export default function QuizzPlay() {
	// const { score } = useContext(QuizzContext);

	return (
		<main className='page_container sky page_quizz_play'>
			<Nav />
			<img className='clouds_1' src={clouds} alt='' width={704} height={462} />
			<img className='clouds_2' src={clouds} alt='' width={704} height={462} />
			<QuizzGame />
		</main>
	);
}
