import React from "react";

export default function Contexte() {
	return (
		<>
			{/* <h4 className='title'>Lily Context</h4> */}
			<p className='description'>
				Un jour, <span className='oliver'>Propser </span>a trouvé une petite entrée vers cette
				<span className='oliver'> discothèque abandonnée...</span>
			</p>
			<div className='video_container'>
				<iframe
					width='560'
					height='315'
					src='https://www.youtube.com/embed/ZOQiV9ggBb4?si=kpRS1wNNCdN1k4Ls'
					title='YouTube video player'
					frameBorder='0'
					allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share'
					allowFullScreen></iframe>
			</div>
		</>
	);
}
