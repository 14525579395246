import React from "react";

export default function ResultIncroyableNul() {
	return (
		<>
			<h4 className='resultat__commentaire_title'>Tu es incroyable !</h4>
			<p className='resultat__commentaire_description'>
				Ce que tu viens de faire est très fort ! Avoir tout faux c’est aussi difficile qu’avoir
				tout juste. Un seul mot: Bravo !
			</p>
		</>
	);
}
