import React from "react";

export default function LilyQuestions() {
	return (
		<div>
			<p className='description'>
				<span className='oliver'>
					Et vous ? Qu'en pensez-vous ? <br />
				</span>
				Voici quelques questions pour entrer en discussion...
			</p>
			<ul className='questions-list'>
				<li>Que vient faire Prosper dans « La Liesse »?</li>
				<li>Comment réagit-il quand 3 personnes y viennent à leur tour? Pourquoi?</li>
				<li>
					Prosper possède 3 Playmobils représentants son père, sa mère et son chien
					(Chipsy).Que comprenons-nous lorsqu’il joue avec?
				</li>
			</ul>
		</div>
	);
}
