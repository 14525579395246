import React, { useContext } from "react";
import "./ModalHerve.scss";
import "./ModalFoxesTemplate.scss";
import ModalContext from "../../../ModalContext";
import HerveContexte from "../../PageTemplates/Foxes/Herve/HerveContexte";
import HerveInterview from "../../PageTemplates/Foxes/Herve/HerveInterview";
import HerveQuestions from "../../PageTemplates/Foxes/Herve/HerveQuestions";
import ContentTemplate from "../../PageTemplates/Foxes/ContentTemplate";

import hervePopup from "../../../assets/img/foxes/persos/herve_popup.png";

export default function ModalHerve() {
	const { closeModal } = useContext(ModalContext);

	return (
		<div id='modalherve' className='modal__wrapper modal__wrapper--foxes-template'>
			{/* <TemplateInternalNavigation /> */}
			<ContentTemplate
				title='Hervé'
				subtitle="« Hervé n'est pas disponible pour le moment »"
				characterPicture={hervePopup}
				templateContexte={<HerveContexte />}
				templateQuestions={<HerveQuestions />}
				templateInterview={<HerveInterview />}
			/>
			<div className='modal__content__close' onClick={closeModal}></div>
		</div>
	);
}
