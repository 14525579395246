import React, { useEffect, useRef, useState } from "react";
import "./CourriersDuCoeur.scss";
import "../styles/clouds.scss";
import RenardLoading from "../assets/lottie/renard_chargement";
import Lottie from "lottie-react";
import VideoSpectacle from "./PagesComponents/AvantSpectacle/VideoSpectacle";
import Nav from "../components/Layout/Nav";
import { gql, useQuery } from "@apollo/client";

import clouds from "../assets/img/intro/clouds.webp";
import coeur from "../assets/img/courriers/coeur.png";
import iconCourrierGrou from "../assets/img/courriers/courrier_grou_picto.webp";
import iconCourrierFoxes from "../assets/img/courriers/courrier_foxes_picto2.webp";

const getCourriersGrou = gql`
	query getCourriers {
		bonus(id: "cG9zdDoxMzU4") {
			title
			acf_courriers_content {
				courriersFoxes {
					mediaDetails {
						sizes(include: MEDIUM_LARGE) {
							sourceUrl
						}
					}
				}
				courriersGrou {
					mediaDetails {
						sizes(include: MEDIUM_LARGE) {
							sourceUrl
						}
					}
				}
			}
		}
	}
`;
const getCourriersFoxes = gql`
	query getCourriers {
		bonus(id: "cG9zdDoxMzU4") {
			title
			acf_courriers_content {
				courriersFoxes {
					mediaDetails {
						sizes(include: MEDIUM_LARGE) {
							sourceUrl
						}
					}
				}
			}
		}
	}
`;

export default function CourriersDuCoeur() {
	const [currentCourrier, setCurrentCourrier] = useState("grou");
	const [gallerieData, setGallerieData] = useState([]);
	const [currentPicIndex, setcurrentPicIndex] = useState(0);
	const [displayedPicturesOnDom, setDisplayedPicturesOnDom] = useState([]);
	const [isIndicationZoneHidden, setisIndicationZoneHidden] = useState(false);

	const followerDiv = useRef(null);

	const {
		loading: loadingCourriersGrou,
		error: errorCourriersGrou,
		data: dataCourriersGrou,
	} = useQuery(getCourriersGrou);
	const {
		loading: loadingCourriersFoxes,
		error: errorCourriersFoxes,
		data: dataCourriersFoxes,
	} = useQuery(getCourriersFoxes);

	function shuffleDrawingsArray(array) {
		let currentIndex = array.length,
			randomIndex;

		// While there remain elements to shuffle.
		while (currentIndex !== 0) {
			// Pick a remaining element.
			randomIndex = Math.floor(Math.random() * currentIndex);
			currentIndex--;

			// And swap it with the current element.
			[array[currentIndex], array[randomIndex]] = [array[randomIndex], array[currentIndex]];
		}

		return array;
	}
	function prepareDrawingsArrayFromData(data) {
		const outputTab = [];
		// eslint-disable-next-line
		data.map((drawing, i) => {
			if (drawing.mediaDetails.sizes) {
				outputTab.push(drawing.mediaDetails.sizes[0].sourceUrl);
			}
		});
		shuffleDrawingsArray(outputTab);
		return outputTab;
	}

	function handleMouseMove(ev) {
		if (!ev || !followerDiv.current) return;
		followerDiv.current.style.left = `${ev.pageX}px`;
		followerDiv.current.style.top = `${ev.pageY}px`;
	}

	function handleClick(ev) {
		if (!ev) return;
		if (!isIndicationZoneHidden) {
			setisIndicationZoneHidden(true);
		}
		if (currentPicIndex < gallerieData.length - 1) {
			setcurrentPicIndex(currentPicIndex + 1);
		} else {
			setcurrentPicIndex(0);
		}
		// displayedPictures
		let tempDisplay = [...displayedPicturesOnDom];
		tempDisplay.push({
			mediaItemUrl: gallerieData[currentPicIndex],
			x: ev.pageX,
			y: ev.pageY,
		});
		setDisplayedPicturesOnDom(tempDisplay);
	}

	function is_touch_enabled() {
		return "ontouchstart" in window || navigator.maxTouchPoints > 0 || navigator.msMaxTouchPoints > 0;
	}

	function switchCourrierType(type) {
		setCurrentCourrier(type);
		resetPage();
	}

	function resetPage() {
		setcurrentPicIndex(0);
		setisIndicationZoneHidden(false);
		setDisplayedPicturesOnDom([]);
	}

	useEffect(() => {
		window.addEventListener("mousemove", handleMouseMove);
	});

	useEffect(() => {
		if (dataCourriersGrou && currentCourrier === "grou") {
			const courrier = prepareDrawingsArrayFromData(
				dataCourriersGrou.bonus.acf_courriers_content.courriersGrou
			);
			setGallerieData(courrier);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [loadingCourriersGrou, currentCourrier]);

	useEffect(() => {
		if (dataCourriersFoxes && currentCourrier === "foxes") {
			const courrier = prepareDrawingsArrayFromData(
				dataCourriersFoxes.bonus.acf_courriers_content.courriersFoxes
			);
			setGallerieData(courrier);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [loadingCourriersFoxes, currentCourrier]);

	if (
		(loadingCourriersGrou && currentCourrier === "grou") ||
		(loadingCourriersFoxes && currentCourrier === "foxes")
	) {
		return (
			<div className='page_container sky page_courrier is_loading'>
				<Nav />

				<div className='loading_container'>
					<Lottie className='renardLoading' animationData={RenardLoading} loop autoplay />
					<p>Chargement du courrier…</p>
				</div>
			</div>
		);
	}
	if (errorCourriersGrou) {
		return (
			<div className='page_container sky page_courrier'>
				<div className='loading_container'>{`Error! ${errorCourriersGrou.message}`}</div>
			</div>
		);
	}
	if (errorCourriersFoxes) {
		return (
			<div className='page_container sky page_courrier'>
				<div className='loading_container'>{`Error! ${errorCourriersFoxes.message}`}</div>
			</div>
		);
	}

	const followerModulo =
		currentPicIndex % 4 === 0
			? "first"
			: currentPicIndex % 4 === 1
			? "second"
			: currentPicIndex % 4 === 2
			? "third"
			: "fourth";
	return (
		<main className='page_container sky page_courrier'>
			<Nav />

			<img className='clouds_1' src={clouds} alt='' width={704} height={462} />
			<img className='clouds_2' src={clouds} alt='' width={704} height={462} />
			<div className='titling'>
				<h1>
					Les courriers du <img className='coeur' src={coeur} alt='' />
				</h1>

				<div className='courrier_selection'>
					<button
						className={`button button--orange button--rounded button--centered button--oliver ${
							currentCourrier === "grou" ? "is_current" : "is_not_current"
						}`}
						disabled={currentCourrier === "grou"}
						onClick={() => switchCourrierType("grou")}>
						<img className='button_icon' src={iconCourrierGrou} alt='' />
						Grou
					</button>
					<button
						className={`button button--orange button--rounded button--centered button--oliver ${
							currentCourrier === "foxes" ? "is_current" : "is_not_current"
						}`}
						disabled={currentCourrier === "foxes"}
						onClick={() => switchCourrierType("foxes")}>
						<img className='button_icon' src={iconCourrierFoxes} alt='' />
						Foxes
					</button>
				</div>
			</div>

			<div className='courrier_content_container' onClick={(ev) => handleClick(ev)}>
				<div ref={followerDiv} className={`follower ${followerModulo}`}>
					<img src={gallerieData[currentPicIndex]} alt="un super dessin d'enfant" />
				</div>
				<div className={`indicationZone ${isIndicationZoneHidden ? "is_hidden" : ""}`}>
					<VideoSpectacle
						webmUrl='/videos/arrows/Arrow_Element_24_Color-vp9-chrome.webm'
						mp4Url='/videos/arrows/Arrow_Element_24_Color-hevc-safari.mp4'
						loop={true}
					/>
					{/* <video autoPlay muted loop playsInline>
						<source src='/videos/arrows/arrow_drop_drawing.mp4'></source>
					</video> */}
					{is_touch_enabled() && (
						<p>Tape ici pour déposer des dessins et voir les suivants</p>
					)}
					{!is_touch_enabled() && (
						<p>Clicke ici pour déposer les dessins et voir les suivants</p>
					)}
				</div>

				{displayedPicturesOnDom.map((e, i) => (
					<div
						className='displayedPicture'
						key={i}
						style={{
							left: `${e.x}px`,
							top: `${e.y}px`,
						}}>
						<img src={e.mediaItemUrl} alt='' />
					</div>
				))}
			</div>
			<div className='lol'>
				<a
					className='cta_orangered submitDrawing'
					href='https://cierenards.be/proposes-ton-dessin/'>
					Proposer mon dessin
				</a>
			</div>
		</main>
	);
}
