import React from "react";
import "./IleFoxes.scss";

export default function IleFoxes() {
	return (
		<>
			<div id='ilefoxes' className='Island'>
				<div className='video_container'>
					<video autoPlay muted loop playsInline>
						<source
							src='/videos/iles/ile_foxes-hevc-safari.mp4'
							type='video/quicktime'></source>
						<source
							src='/videos/iles/ile_foxes-vp9-chrome.webm'
							type='video/webm'></source>
					</video>
				</div>
				<div className='text'>
					<h2 className='island_title'>Foxes</h2>
					<p>Découvrez-en plus sur le spectacle et rebranchez La Liesse ! </p>
				</div>
			</div>
		</>
	);
}
