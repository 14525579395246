import { Routes, Route } from "react-router-dom";
import { ApolloProvider } from "@apollo/client";
import { QuizzProvider } from "./QuizzContext";
import { ModalProvider } from "./ModalContext";
import client from "./services/appolo.js";

import HomeIslands from "./Pages/HomeIslands";
import Intro from "./Pages/Intro";
import Foxes from "./Pages/Foxes";
import AvantLeSpectacle from "./Pages/AvantLeSpectacle";
import CourriersDuCoeur from "./Pages/CourriersDuCoeur";
import QuizzIntro from "./Pages/QuizzIntro.jsx";
import QuizzPlay from "./Pages/QuizzPlay.jsx";
import QuizzResultat from "./Pages/QuizzResultat.jsx";
import GrouLeJeu from "./Pages/GrouLeJeu.jsx";
import Testing from "./Pages/Testing";
import ChessGame from "./Pages/ChessGame";

import TestPage from "./Pages/TestPage.jsx";

function App() {
	return (
		<>
			<ModalProvider>
				<QuizzProvider>
					<ApolloProvider client={client}>
						<Routes>
							<Route path='/' element={<Intro />} />
							<Route path='/iles' element={<HomeIslands />} />
							<Route path='/foxes' element={<Foxes />} />
							<Route path='/testing' element={<Testing />} />
							<Route path='/test-page' element={<TestPage />} />
							<Route path='/chess' element={<ChessGame />} />
							<Route
								path='/avant-le-spectacle'
								element={<AvantLeSpectacle />}
							/>
							<Route
								path='/courriers-du-coeur'
								element={<CourriersDuCoeur />}
							/>
							<Route path='/quizz' element={<QuizzIntro />} />
							<Route path='/quizz/play' element={<QuizzPlay />} />
							<Route path='/quizz/resultat' element={<QuizzResultat />} />
							<Route path='/grou-le-jeu' element={<GrouLeJeu />} />
						</Routes>
					</ApolloProvider>
				</QuizzProvider>
			</ModalProvider>
		</>
	);
}

export default App;
