import React, { useRef, useState, useContext, useEffect } from "react";
import Character from "./Character";
import ObjectAsset from "./Object";
import confettis from "../../../assets/lottie/data.json";
import findEffect from "../../../assets/lottie/dataTest4.json";
import ModalContext from "../../../ModalContext";
import ModalHerve from "../../../Templates/ModalTemplates/Foxes/ModalHerve";
import ModalLily from "../../../Templates/ModalTemplates/Foxes/ModalLily";
import ModalProsper from "../../../Templates/ModalTemplates/Foxes/ModalProsper";
import ModalBambi from "../../../Templates/ModalTemplates/Foxes/ModalBambi";
import ModalSoon from "../../../Templates/ModalTemplates/Foxes/ModalSoon";
import ModalAffiche from "../../../Templates/ModalTemplates/Foxes/ModalAffiche";
import ModalCassette from "../../../Templates/ModalTemplates/Foxes/ModalCassette";
import ModalLaLiesse from "../../../Templates/ModalTemplates/Foxes/ModalLaLiesse";
import "./FoxesGameContainer.scss";
import Lottie from "lottie-react";

import winSoundFile from "../../../assets/music/winSound.mp3"; // importing the music

import herveDark from "../../../assets/img/foxes/persos/herve_dark.png";
import bambiDark from "../../../assets/img/foxes/persos/bambi_dark.png";
import baptisteDark from "../../../assets/img/foxes/persos/baptiste_dark.png";
import lilyDark from "../../../assets/img/foxes/persos/lily_dark.png";
import foxDark from "../../../assets/img/foxes/objects/fox_dark.png";
import laliessseDark from "../../../assets/img/foxes/objects/laliesse_dark.webp";
import herveLight from "../../../assets/img/foxes/persos/herve_light.png";
import bambiLight from "../../../assets/img/foxes/persos/bambi_light.png";
import baptisteLight from "../../../assets/img/foxes/persos/baptiste_light.png";
import lilyLight from "../../../assets/img/foxes/persos/lily_light.png";
import foxLight from "../../../assets/img/foxes/objects/fox_light.png";
import laliessseLight from "../../../assets/img/foxes/objects/laliesse_light.webp";

import journalDark from "../../../assets/img/foxes/objects/journal_dark.png";
import miroDark from "../../../assets/img/foxes/objects/micro_dark.png";
import droneDark from "../../../assets/img/foxes/objects/drone_dark.png";
import casqueDark from "../../../assets/img/foxes/objects/casque_dark.png";
import pianoDark from "../../../assets/img/foxes/objects/piano_dark.png";
import gantsDark from "../../../assets/img/foxes/objects/gant_dark.png";
import cassetteDark from "../../../assets/img/foxes/objects/cassette_dark.png";
import afficheDark from "../../../assets/img/foxes/objects/affiche_dark.png";

import journalLight from "../../../assets/img/foxes/objects/journal_light.png";
import miroLight from "../../../assets/img/foxes/objects/micro_light.png";
import droneLight from "../../../assets/img/foxes/objects/drone_light.png";
import casqueLight from "../../../assets/img/foxes/objects/casque_light.png";
import pianoLight from "../../../assets/img/foxes/objects/piano_light.png";
import gantsLight from "../../../assets/img/foxes/objects/gant_light.png";
import cassetteLight from "../../../assets/img/foxes/objects/cassette_light.png";
import afficheLight from "../../../assets/img/foxes/objects/affiche_light.png";

import neonBar from "../../../assets/img/foxes/fete/neon_bar.webp";
import neonPoleDance from "../../../assets/img/foxes/fete/neon_pole_dance.webp";
import neonVentilo from "../../../assets/img/foxes/fete/neon_ventilo.webp";
import lumiereBafleDroit from "../../../assets/img/foxes/fete/lumière_baffle_droite.webp";
import lumiereBafleGauche from "../../../assets/img/foxes/fete/lumiere_baffle_gauche.webp";

export default function FoxesGameContainer({ foundObjects, findObject }) {
	const lightContainer = useRef(null);
	// const [maskPosition, setMaskPosition] = useState({ x: 100, y: 100 });
	const { toggleModal } = useContext(ModalContext);
	const [gameCompleted, setGameCompleted] = useState(false);
	const lottieRef = useRef();
	const findEffectRef = useRef();

	const windSound = new Audio(winSoundFile);

	function checkIfWin() {
		const allObjectsFound =
			Object.values(foundObjects).filter((ObjectIsfound) => ObjectIsfound === false).length < 1;
		if (allObjectsFound && !gameCompleted) {
			setGameCompleted(true);
			setTimeout(() => {
				lottieRef.current.play();
				windSound.play();
			}, 1000);
		}
	}
	function handleMove(e) {
		let offsetX = document.querySelector("#lightContainer").getBoundingClientRect().x;

		lightContainer.current.style.clipPath = `circle(var(--maskScale) at ${e.pageX - offsetX}px ${
			e.pageY
		}px)`;
		lightContainer.current.style.webkitClipPath = `circle(var(--maskScale) at ${e.pageX - offsetX}px ${
			e.pageY
		}px)`;

		// TEST WITH DYANMIC CSS VARIABLES
		// let root = document.documentElement;
		// root.style.setProperty("--mouse-x", e.clientX + "px");
		// root.style.setProperty("--mouse-y", e.clientY + "px");

		// PASSING CCS VARIABLES
		// let root = document.documentElement;
		// let lightContainer = document.querySelector("#lightContainer");
		// root.style.setProperty("--mouse-x", e.pageX - offsetX + "px");
		// root.style.setProperty("--mouse-y", e.pageY + "px");
		// lightContainer.style.maskPosition = `${e.pageX - offsetX} ${e.pageY} px`;
		// lightContainer.style.setProperty("mask-position", e.pageX - offsetX + "px");

		// const mask = document.querySelector("#mask");
		// mask.setAttribute("cx", e.pageX - offsetX);
		// mask.setAttribute("cy", e.pageY);
		// console.log(mask);
		// setMaskPosition({ x: e.pageX - offsetX, y: e.pageY });
	}

	useEffect(() => {
		checkIfWin();

		document.addEventListener("mousemove", handleMove);

		return () => {
			document.removeEventListener("mousemove", handleMove);
		};
	});

	return (
		<div id='foxesContainer'>
			<Lottie
				id='findEffect'
				lottieRef={findEffectRef}
				loop={false}
				animationData={findEffect}
				autoplay={false}
			/>

			<div id='darkContainer'>
				{/* CHARACTERS */}
				<Character id='herve_dark' src={herveDark} />
				<Character id='bambi_dark' src={bambiDark} />
				<Character id='baptiste_dark' src={baptisteDark} />
				<Character id='lily_dark' src={lilyDark} />
				<Character id='fox_dark' src={foxDark} />
				<Character id='laliesse_dark' src={laliessseDark} />

				{/* OBJECTS DARK */}
				<ObjectAsset id='journal_dark' src={journalDark} object='object_dark' />
				<ObjectAsset id='micro_dark' src={miroDark} object='object_dark' />
				<ObjectAsset id='drone_dark' src={droneDark} object='object_dark' />
				<ObjectAsset id='casque_dark' src={casqueDark} object='object_dark' />
				<ObjectAsset id='piano_dark' src={pianoDark} object='object_dark' />
				<ObjectAsset id='gant_dark' src={gantsDark} object='object_dark' />
				<ObjectAsset id='cassette_dark' src={cassetteDark} object='object_dark' />
				<ObjectAsset id='affiche_dark' src={afficheDark} object='object_dark' />
			</div>
			<div ref={lightContainer} id='lightContainer'>
				{/* <svg id='masker'>
					<defs>
						<clipPath id='mask'>
							<circle r='100' />
						</clipPath>
					</defs>
				</svg> */}
				{/* CHARACTERS */}
				<Character id='herve_light' src={herveLight} />
				<Character id='bambi_light' src={bambiLight} />
				<Character id='baptiste_light' src={baptisteLight} />
				<Character id='lily_light' src={lilyLight} />
				<Character id='fox_light' src={foxLight} />
				<Character id='laliesse_light' src={laliessseLight} />

				{/* OBJECTS LIGHT */}
				<ObjectAsset
					id='journal_light'
					src={journalLight}
					object='object_light'
					// onClick={() => updateFoundObjects("journal")}
					onClick={(e) => {
						findObject("journal", e, findEffectRef);
					}}
				/>

				<ObjectAsset
					id='drone_light'
					src={droneLight}
					object='object_light'
					onClick={(e) => {
						findObject("drone", e, findEffectRef);
					}}
				/>
				<ObjectAsset
					id='casque_light'
					src={casqueLight}
					object='object_light'
					onClick={(e) => findObject("casque", e, findEffectRef)}
				/>
				<ObjectAsset
					id='micro_light'
					src={miroLight}
					object='object_light'
					onClick={(e) => findObject("micro", e, findEffectRef)}
				/>
				<ObjectAsset
					id='piano_light'
					src={pianoLight}
					object='object_light'
					onClick={(e) => findObject("piano", e, findEffectRef)}
				/>
				<ObjectAsset
					id='gant_light'
					src={gantsLight}
					object='object_light'
					onClick={(e) => findObject("gants", e, findEffectRef)}
				/>
				<ObjectAsset
					id='cassette_light'
					src={cassetteLight}
					object='object_light'
					onClick={(e) => {
						toggleModal(
							<ModalCassette
								e={e}
								findObject={findObject}
								findEffectRef={findEffectRef}
							/>
						);
					}}
				/>
				<ObjectAsset
					id='affiche_light'
					src={afficheLight}
					object='object_light'
					onClick={(e) => {
						toggleModal(
							<ModalAffiche
								e={e}
								findObject={findObject}
								findEffectRef={findEffectRef}
							/>
						);
					}}
				/>

				<div></div>
			</div>
			<div id='hoverContainer'>
				<Character
					id='herve_hover'
					src={herveLight}
					hoverCharacter={true}
					onClick={() => {
						toggleModal(<ModalHerve />);
					}}
				/>
				<Character
					id='bambi_hover'
					src={bambiLight}
					hoverCharacter={true}
					onClick={() => {
						toggleModal(<ModalBambi />);
					}}
				/>
				<Character
					id='baptiste_hover'
					src={baptisteLight}
					hoverCharacter={true}
					onClick={() => {
						toggleModal(<ModalProsper />);
					}}
				/>
				<Character
					id='lily_hover'
					src={lilyLight}
					hoverCharacter={true}
					onClick={() => {
						toggleModal(<ModalLily />);
					}}
				/>
				<Character
					id='fox_hover'
					src={foxLight}
					hoverCharacter={true}
					onClick={() => {
						toggleModal(<ModalSoon />);
					}}
				/>
				<Character
					id='laliesse_hover'
					src={laliessseLight}
					hoverCharacter={true}
					onClick={() => {
						toggleModal(<ModalSoon />);
					}}
				/>
			</div>
			{gameCompleted && (
				<div id='feteContainer'>
					<Lottie
						lottieRef={lottieRef}
						className='confettis'
						autoplay={false}
						loop={false}
						animationData={confettis}
					/>
					<img id='neonBar' className='feteLights' src={neonBar} alt='' />
					<img id='neonPoleDance' className='feteLights' src={neonPoleDance} alt='' />
					<img id='neonVentilo' className='feteLights' src={neonVentilo} alt='' />
					<img
						id='lumiereBafleDroit'
						className='feteLights'
						src={lumiereBafleDroit}
						alt=''
					/>
					<img
						id='lumiereBafleGauche'
						className='feteLights'
						src={lumiereBafleGauche}
						alt=''
					/>
				</div>
			)}
		</div>
	);
}
