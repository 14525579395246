import React, { createContext, useState } from "react";
import Modal from "./components/Modals/Modal";

const ModalContext = createContext();

export function ModalProvider({ children }) {
	const [modalContent, setModalContent] = useState(false);
	const [centerModalVertically, setCenterModalVertically] = useState(null);

	document.onkeydown = function (e) {
		if (e.key === "Escape") {
			toggleModal(false);
		}
	};
	function closeModal() {
		toggleModal(false);
	}
	function toggleModal(component) {
		if (typeof component == "object") {
			setModalContent(<Modal closeModal={closeModal}>{component}</Modal>);
		} else {
			setModalContent(null);
		}
	}
	function calculateModalDisposition(modalContent) {
		const modal = document.querySelector("#modal .modal__content");
		if (!modal) return;
		// console.log(modal);
		// console.log("resized");
		// console.log("le modal :" + modal.clientHeight);
		// console.log("la fenetre :" + window.innerHeight);
		// console.log(modal.clientHeight > window.innerHeight - 40);
		// console.log(modal.clientHeight > window.innerHeight);
		if (modal.clientHeight > window.innerHeight - 40) {
			setCenterModalVertically(false);
			// console.log("on met pas de  flex");
		} else {
			setCenterModalVertically(true);
			// console.log("on met du flex");
		}
	}
	return (
		<ModalContext.Provider
			value={{
				modalContent,
				toggleModal,
				closeModal,
				setModalContent,
				centerModalVertically,
				calculateModalDisposition,
			}}>
			{children}
		</ModalContext.Provider>
	);
}
export default ModalContext;
