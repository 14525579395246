import React, { useContext } from "react";
import QuizzContext from "../../../../QuizzContext";
import VideoModule from "../../AvantSpectacle/VideoSpectacle";
import "./Question3.scss";

export default function Question3() {
	const { handleAnswer } = useContext(QuizzContext);

	return (
		<div id='question3'>
			<h2 className='la_question'>Choisis le meilleur moment DU SPECTACLE pour « taper »…</h2>

			<div className='answers'>
				<button className='answer' onClick={() => handleAnswer(false)}>
					<h4>Avant</h4>
					<VideoModule
						mp4Url='/videos/quizz/aplauss2-hevc-safari.mp4'
						webmUrl='/videos/quizz/aplauss2-vp9-chrome.webm'
						loop={true}
					/>
					<p>( sur ton visage )</p>
				</button>
				<button className='answer' onClick={() => handleAnswer(false)}>
					<h4>Pendant</h4>

					<VideoModule
						mp4Url='/videos/quizz/aplauss1-hevc-safari.mp4'
						webmUrl='/videos/quizz/aplauss1-vp9-chrome.webm'
						loop={true}
					/>
					<p>( sur tes voisins )</p>
				</button>
				<button className='answer' onClick={() => handleAnswer(true)}>
					<h4>Après</h4>
					<VideoModule
						mp4Url='/videos/quizz/aplauss3-hevc-safari.mp4'
						webmUrl='/videos/quizz/aplauss3-vp9-chrome.webm'
						loop={true}
					/>
					<p>( dans tes mains )</p>
				</button>
			</div>
		</div>
	);
}
