import React from "react";

export default function ResultCata() {
	return (
		<div id="resultat_cata">
			<h4 className='resultat__commentaire_title'>C'est la cata !</h4>
			<p className='resultat__commentaire_description'>
				Que faire de toi ? S’il te plaît ne viens pas. La bise à Cyril Hanouna.
			</p>
		</div>
	);
}
