import React, { useContext, useRef } from "react";
import QuizzContext from "../../../../QuizzContext";
import VideoModule from "../../AvantSpectacle/VideoSpectacle";
import "./Question6.scss";

export default function Question6() {
	const { checkTheInputsAnswers } = useContext(QuizzContext);
	const inputs = useRef(null);

	return (
		<div id='question6'>
			<h2 className='la_question'>Coche toutes les émotions que tu peux avoir au théâtre !</h2>
			<VideoModule
				webmUrl='/videos/quizz/23_anim_avant_spectacle-vp9_VP9.webm'
				mp4Url='/videos/quizz/23_anim_avant_spectacle-vp9.mp4'
				loop={true}
			/>
			<div className='answers'>
				<ul ref={inputs}>
					<li className='ga'>
						<input type='checkbox' name='agreement' />
						<label htmlFor='agreement'>La joie</label>
					</li>
					<li className='ga'>
						<input type='checkbox' name='agreement' />
						<label htmlFor='agreement'>La peur</label>
					</li>
					<li className='ga'>
						<input type='checkbox' name='agreement' />
						<label htmlFor='agreement'>La tristesse</label>
					</li>
					<li className='ga'>
						<input type='checkbox' name='agreement' />
						<label htmlFor='agreement'>L'excitation</label>
					</li>
					<li className='ga'>
						<input type='checkbox' name='agreement' />
						<label htmlFor='agreement'>le suspens</label>
					</li>
					<li className='ga'>
						<input type='checkbox' name='agreement' />
						<label htmlFor='agreement'>le soulagement</label>
					</li>
					<li className='ga'>
						<input type='checkbox' name='agreement' />
						<label htmlFor='agreement'>Le dégout</label>
					</li>

					<li className='ga'>
						<input type='checkbox' name='agreement' />
						<label htmlFor='agreement'>La surprise</label>
					</li>
					<li className='ga'>
						<input type='checkbox' name='agreement' />
						<label htmlFor='agreement'>Le rire</label>
					</li>
					<li className='ga'>
						<input type='checkbox' name='agreement' />
						<label htmlFor='agreement'>L'émerveillement</label>
					</li>
					<li className='ga'>
						<input type='checkbox' name='agreement' />
						<label htmlFor='agreement'>L'envie </label>
					</li>
					<li className='ga'>
						<input type='checkbox' name='agreement' />
						<label htmlFor='agreement'>L'ennui</label>
					</li>
				</ul>

				{/* <VideoModule
						webmUrl='/videos/quizz/29_anim_avant_spectacle_VP9.webm'
						mp4Url='/videos/quizz/29_anim_avant_spectacle.mp4'
						loop={true}
					/> */}
				<button className='verify cta_orangered' onClick={() => checkTheInputsAnswers(inputs)}>
					Vérifier ma réponse
				</button>
			</div>
		</div>
	);
}
