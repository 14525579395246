import React, { useContext } from "react";
import "./ModalAffiche.scss";
import ModalContext from "../../../ModalContext";
import Pan from "./ModalPoster";
export default function ModalAffiche({ findObject, findEffectRef, e }) {
	const { closeModal } = useContext(ModalContext);

	return (
		<div id='modalaffiche' className='modal__wrapper modal__wrapper--affiche'>
			<div
				className='modal__content__close'
				onClick={() => {
					closeModal();
					findObject("affiche", e, findEffectRef);
				}}></div>

			<div className='content'></div>
			<Pan />
		</div>
	);
}
