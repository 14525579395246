import React from "react";
import "./Reponse2.scss";

export default function Reponse2() {
	return (
		<div id='reponse2' className="reponse__wrapper">
			<h5 className='reponse__tag'>Réponse</h5>
			<h4 className='reponse__title'>Rien !</h4>
			<h6 className='reponse__subtitle'>( Sorry... je sais que tu adores les brocolis... )</h6>
			<p className='reponse__detail'>
				Aucune nourriture n’est autorisée dans la salle de spectacle…Prévoir un petit encas
				avant ou après pour les plus affamés !
			</p>
			<p className='reponse__detail'>( Boire un peu d’eau, discrètement, reste toléré )</p>
		</div>
	);
}
