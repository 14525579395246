import React, { useEffect, useContext } from "react";
import { Link } from "react-router-dom";
import QuizzContext from "../QuizzContext";

import "./QuizzIntro.scss";
import Nav from "../components/Layout/Nav";

import clouds from "../assets/img/intro/clouds.webp";

export default function Quizz() {
	const { resetQuizz } = useContext(QuizzContext);

	useEffect(() => {
		resetQuizz();
	});

	return (
		<main className='page_container sky page_quizz_intro'>
			<Nav />
			<img className='clouds_1' src={clouds} alt='' width={704} height={462} />
			<img className='clouds_2' src={clouds} alt='' width={704} height={462} />
			<h1>Le Quizz !</h1>
			<div className='explanation'>
				<p className='catchphrase'>
					Es-tu une chouette spectatrice ? Un spectateur sympathique ?
					<br />
					On va voir ça…
				</p>
				<p className='expli'>
					Pour chacune des <span className='oliver'>propositions suivantes </span> essaye
					de donner la bonne réponse.
				</p>
			</div>
			<Link to={"/quizz/play"} className='cta_orangered'>
				C'est parti mon kiki
			</Link>
		</main>
	);
}
