import React from "react";

export default function VideoSpectacle({ webmUrl, mp4Url, loop, className, preload, autoplay, playsInline, lazy }) {
	// console.log(autoplay, playsInline);

	return (
		<video
			muted
			loop={loop}
			className={className}
			autoPlay={autoplay ? autoplay : true}
			playsInline={playsInline ? playsInline : true}>
			<source src={mp4Url} type='video/mp4;codecs=hvc1'></source>
			<source src={webmUrl} type='video/webm'></source>
		</video>
	);
}
