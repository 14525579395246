import React from "react";
import VideoModule from "../../AvantSpectacle/VideoSpectacle";
import "./Reponse1.scss";

export default function Reponse1() {
	return (
		<div id='reponse1' className="reponse__wrapper">
			<h5 className='reponse__tag'>Réponse</h5>
			<VideoModule
				className='brain'
				mp4Url='/videos/quizz/cerveau_vibration-hevc-safari.mp4'
				webmUrl='/videos/quizz/cerveau_vibration-vp9-chrome.webm'
				loop={true}
			/>
			<h4 className='reponse__title'>intense</h4>
			<h6 className='reponse__subtitle'>( mais ça marche aussi avec les cerveaux lents ... )</h6>
			<p className='reponse__detail'>
				Bon, il ne faut pas se stresser non plus… Mais être calme et disponible quand ça
				commence rend l’expérience de spectacle encore meilleure !
			</p>
		</div>
	);
}
