import React from "react";
import { Link } from "react-router-dom";
import "./ModalGrouLeJeu.scss";
import arrowDouble from "../../assets/lottie/arrow6.json";
import radarEffect from "../../assets/lottie/0IXDhb8qcn.json";
import Lottie from "lottie-react";

export default function ModalGrouLeJeu() {
	function is_touch_enabled() {
		return "ontouchstart" in window || navigator.maxTouchPoints > 0 || navigator.msMaxTouchPoints > 0;
	}

	return (
		<div id='modalgroulejeu' className='modal__wrapper modal__wrapper--blue'>
			<div className='text'>
				<h4>GRoU ! Le JeU</h4>
				<div className='modal__description'>
					<p className='oliver'>
						Lors dES DIVERS confinementS liÉS à l'épidémie de Covid 19 nous avons
						annulÉ 160 représentations de notre spectacle « Grou ! »
					</p>
					<p>
						Comme on a eu un peu de temps on en a profité pour
						<span className='oliver'> fabriquer un Jeu…</span>Tu peux l'essayer ici
						si tu veux !
					</p>
				</div>

				{is_touch_enabled() && (
					<div className='warning'>
						<Lottie id='superRadar' animationData={radarEffect} loop autoplay />
						<h5>Attention !</h5>
						<p>
							Notre super détecteur radar nous informe que tu utilises un
							appareil tactile. Malheureusement,Ce jeu a été optimisé pour
							être joué sur un <span className='oliver'>ordinateur</span> avec
							un <span className='oliver'>clavier</span> et une{" "}
							<span className='oliver'>souris</span>;) Revenez plus tard pour
							essayer cette merveille !
						</p>
					</div>
				)}
				{!is_touch_enabled() && (
					<div className='go-play-game'>
						<div className='credits'>
							<div className='cred'>
								<h5>Dessins:</h5>
								<p>Baptiste Toulemonde</p>
							</div>
							<div className='cred'>
								<h5>Game Design, Animation, dessins additionnels:</h5>
								<p>Arthur Oudar </p>
							</div>
							<div className='cred'>
								<h5>Musique:</h5>
								<p>Guillaume Vesin</p>
							</div>
						</div>
						<Lottie id='arrowDouble' animationData={arrowDouble} loop autoplay />

						<Link
							to='https://games.gdevelop-app.com/game-d0b9a29e-3132-4142-9d81-fd3ac8613bf5/index.html'
							target='_blank'
							className='cta-arrowed cta-arrowed--orangered'>
							c'est par ici
						</Link>
					</div>
				)}
			</div>
		</div>
	);
}
