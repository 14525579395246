import React, { useContext } from "react";
import "./ModalBambi.scss";
import "./ModalFoxesTemplate.scss";
import ModalContext from "../../../ModalContext";
import BambiContexte from "../../PageTemplates/Foxes/Bambi/BambiContexte";
import BambiInterview from "../../PageTemplates/Foxes/Bambi/BambiInterview";
import BambiQuestions from "../../PageTemplates/Foxes/Bambi/BambiQuestions";
import ContentTemplate from "../../PageTemplates/Foxes/ContentTemplate";

import bambiPopup from "../../../assets/img/foxes/persos/bambi_popup.png";

export default function ModalBambi() {
	const { closeModal } = useContext(ModalContext);

	return (
		<div id='modalbambi' className='modal__wrapper modal__wrapper--foxes-template'>
			<ContentTemplate
				title='Bambi'
				subtitle="« Bambi, candidate à l'émission télé La Voix Junior... »"
				characterPicture={bambiPopup}
				templateContexte={<BambiContexte />}
				templateQuestions={<BambiQuestions />}
				templateInterview={<BambiInterview />}
			/>
			<div className='modal__content__close' onClick={closeModal}></div>
		</div>
	);
}
