import React, { useContext, useEffect, useState } from "react";
import Nav from "../components/Layout/Nav";
import "./Foxes.scss";
import ModalContext from "../ModalContext";
import FoxesGameContainer from "./PagesComponents/Foxes/FoxesGameContainer";
import Toolbar from "./PagesComponents/Foxes/Toolbar";
import "plyr-react/plyr.css";
import foundSoundFile from "../assets/music/diamond-sparkle.mp3"; // importing the music
import cassetteSoundFile from "../assets/music/cassetteSound.wav"; // importing the music
import Lottie from "lottie-react";
import QuestionMark from "../assets/lottie/foxes-question-mark-instructions.json";

import ModalFoxesGameInstructions from "../Templates/ModalTemplates/ModalFoxesGameInstructions";

export default function Foxes() {
	const { modalContent, toggleModal } = useContext(ModalContext);
	const [foundObjects, setFoundObjects] = useState({
		journal: false,
		cassette: false,
		drone: false,
		casque: false,
		piano: false,
		affiche: false,
		gants: false,
		micro: false,
	});

	function findObject(objectName, e, findEffectRef) {
		const foundSound = new Audio(foundSoundFile);
		const cassetteSound = new Audio(cassetteSoundFile);
		updateFoundObjects(objectName);

		if (objectName !== "cassette") {
			foundSound.play();
		}
		if (objectName === "cassette") {
			cassetteSound.play();
		}
		document.documentElement.style.setProperty("--effect-x", e.clientX + "px");
		document.documentElement.style.setProperty("--effect-y", e.clientY + "px");
		findEffectRef.current.play();
		setTimeout(() => {
			findEffectRef.current.stop();
		}, 1000);
	}

	function updateFoundObjects(objectName) {
		setFoundObjects({
			...foundObjects,
			[objectName]: true,
		});
	}
	useEffect(() => {
		toggleModal(false);
		toggleModal(<ModalFoxesGameInstructions />);
		return () => {
			toggleModal(false);
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<div className='page_container page_foxes'>
			{modalContent}
			<Nav />
			<h1>Foxes</h1>
			<Lottie
				className='consignes'
				animationData={QuestionMark}
				loop
				autoplay
				onClick={() => toggleModal(<ModalFoxesGameInstructions />)}
			/>

			<div className='conteneur'>
				<FoxesGameContainer foundObjects={foundObjects} findObject={findObject} />
				<Toolbar foundObjects={foundObjects} />
			</div>
		</div>
	);
}
