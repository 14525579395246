import React from "react";

import { TransformWrapper, TransformComponent } from "react-zoom-pan-pinch";
import poster from "../../../assets/img/foxes/FOXES_v3_RVB_light.webp";
export default function Pan() {
	return (
		<TransformWrapper initialScale={1} limitToBounds={true} centerZoomedOut={true}>
			<TransformComponent>
				<img className='affiche_foxes' src={poster} alt='affiche_foxes' />
			</TransformComponent>
		</TransformWrapper>
	);
}
