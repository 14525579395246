import React, { useContext } from "react";
import { Link } from "react-router-dom";
import QuizzContext from "../QuizzContext";
import Nav from "../components/Layout/Nav";
import "./QuizzResultat.scss";
import ResultBravo from "./PagesComponents/Quizz/Resultats/ResultBravo";
import ResultSuper from "./PagesComponents/Quizz/Resultats/ResultSuper";
import ResultBofBof from "./PagesComponents/Quizz/Resultats/ResultBofBof";
import ResultCata from "./PagesComponents/Quizz/Resultats/ResultCata";
import ResultPasMal from "./PagesComponents/Quizz/Resultats/ResultPasMal";
import ResultIncroyableNul from "./PagesComponents/Quizz/Resultats/ResultIncroyableNul";

import clouds from "../assets/img/intro/clouds.webp";

export default function QuizzPlay() {
	const { score } = useContext(QuizzContext);
	// console.log("initialisation : // score: " + score);

	return (
		<main className='page_container sky page_quizz_resultat'>
			<Nav />
			<img className='clouds_1' src={clouds} alt='' width={704} height={462} />
			<img className='clouds_2' src={clouds} alt='' width={704} height={462} />
			<div className='result_container'>
				<div className='resultat'>
					<h2 className='resultat__title'>ta note : {score} / 7</h2>

					{score >= 7 && <ResultBravo />}
					{(score === 6 || score === 5) && <ResultSuper />}
					{(score === 4 || score === 3) && <ResultPasMal />}
					{score === 2 && <ResultBofBof />}
					{score === 1 && <ResultCata />}
					{score <= 0 && <ResultIncroyableNul />}
				</div>
				<Link to='/iles' className='cta_orange_arrow'>
					Menu
				</Link>
			</div>
		</main>
	);
}
