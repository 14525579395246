import React from "react";

export default function Character({ id, src, hoverCharacter, onClick, onMouseEnter }) {
	return (
		<img
			id={id}
			className={`character ${hoverCharacter ? "hover_character" : ""}`}
			src={src}
			alt=''
			onClick={onClick}
			onMouseEnter={onMouseEnter}
		/>
	);
}
