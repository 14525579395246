import React, { Suspense } from "react";
// COMPONENTS
import Nav from "../components/Layout/Nav";
// STYLES
import "./AvantLeSpectacle.scss";
import paperTexture from "../assets/img/textures/FOND0.png";

const SpectacleContentDatas = React.lazy(() => import("./PagesComponents/AvantSpectacle/SpectacleContentDatas"));

export default function AvantLeSpectacle() {
	return (
		<div className='page_container page_avantlespectacle'>
			<img className='bg-pol' src={paperTexture} alt='' />

			<Nav />
			{/* <SpectacleContentDatas /> */}

			<Suspense fallback={<div>Chargement...</div>}>
				<SpectacleContentDatas />
			</Suspense>
		</div>
	);
}
