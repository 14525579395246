import React from "react";
import BonhomeHover from "../components/test/BonhomeHover";
import WheelOfFortune from "../components/test/WheelOfFortune";
export default function Testing() {
	return (
		<div className='page-container'>
			<WheelOfFortune />
			<BonhomeHover />
		</div>
	);
}
