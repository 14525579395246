import React, { useContext, useEffect, useRef } from "react";
import "./Modal.scss";
import ModalContext from "../../ModalContext";
export default function Modal({ children, closeModal }) {
	const modalContent = useRef(null);
	const { calculateModalDisposition, centerModalVertically } = useContext(ModalContext);

	useEffect(() => {
		calculateModalDisposition();
		window.addEventListener("resize", calculateModalDisposition);
		return () => {
			window.removeEventListener("resize", calculateModalDisposition);
		};
	}, [calculateModalDisposition]);

	return (
		<section id='modal' className={`modal ${centerModalVertically ? "" : "noflex"}`}>
			<div className='modal__content' ref={modalContent}>
				{children}
				<div className='modal__content__close' onClick={closeModal}></div>
			</div>
			<div className='modal__background' onClick={closeModal}></div>
		</section>
	);
}
