import React from "react";

export default function ResultBravo() {
	return (
		<>
			<h4 className='resultat__commentaire_title'>Bravo !</h4>
			<p className='resultat__commentaire_description'>
				Du Grand Art ! C’est nous qui t’applaudissons !
			</p>
		</>
	);
}
