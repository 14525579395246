import React from "react";

export default function ResultBofBof() {
	return (
		<>
			<h4 className='resultat__commentaire_title'>Bof Bof… </h4>
			<p className='resultat__commentaire_description'>Euh… Réessaye pour voir ?</p>
		</>
	);
}
