import React, { useRef } from "react";
import { Link } from "react-router-dom";
import "./IleTadam.scss";
import Lottie from "lottie-react";
import splash from "../../assets/lottie/Fluid10.json";

import locked from "../../assets/img/iles/locked.webp";

export default function IleGrou() {
	const splashRef = useRef(null);
	function reloop() {
		setTimeout(() => {
			splashRef.current.goToAndPlay(0);
		}, 1000);
	}
	return (
		<>
			<Link id='iletadam' className='Island'>
				<div className='warning-soon'>
					<Lottie
						className='splash'
						lottieRef={splashRef}
						animationData={splash}
						onComplete={reloop}
						autoplay
						loop={false}
					/>
					<p>Bientôt disponible</p>
				</div>
				<div className='video_container'>
					<img src={locked} alt='' />
				</div>
				<div className='text'>
					<h2 className='island_title'>Tadam</h2>
					<p>Bientôt...</p>
				</div>
			</Link>
		</>
	);
}
