import React from "react";
import VideoModule from "../../AvantSpectacle/VideoSpectacle";
import "./Reponse4.scss";

export default function Reponse4() {
	return (
		<div id='reponse4' className="reponse__wrapper">
			<h5 className='reponse__tag'>Réponse</h5>

			<h4 className='reponse__title'>SIMPLEMENT...</h4>
			<h6 className='reponse__subtitle'>
				<input type='checkbox' checked name='' id='' />
				<span>éteindre ton téléphone</span>
				
			</h6>
			<p className='reponse__detail'>
				La lumière, le son et la déconcentration font des téléphones une véritable « bombe
				anti-spectacle ». Pour s’en prémunir, deux solutions:<br/> L’extinction ou… la destruction.
			</p>
			<VideoModule
				mp4Url='/videos/quizz/marteau-hevc-safari.mp4'
				webmUrl='/videos/quizz/marteau-vp9-chrome.webm'
				loop={true}
			/>
		</div>
	);
}
