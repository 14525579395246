import React from "react";

export default function LilyInterview() {
	return (
		<div>
			<p className='description'>
				On donne la parole à Baptiste Toulemonde
				<br />
				<span className='oliver'>« C'est qui, pour toi, Prosper ??? »</span>
			</p>
			<div className='video_container'>
				<iframe
					width='560'
					height='315'
					src='https://www.youtube.com/embed/C9K3_j9iH5M?si=a6Ox8G5Du7SM445X'
					title='YouTube video player'
					frameBorder='0'
					allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share'
					allowFullScreen></iframe>
			</div>
		</div>
	);
}
