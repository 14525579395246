import React from "react";

export default function LilyInterview() {
	return (
		<div>
			<p className='description'>
				On donne la parole à Julie Sommervogel
				<br />
				<span className='oliver'>"C'est qui, pour toi, Lily ???"</span>
			</p>
			<div className='video_container'>
				<iframe
					width='560'
					height='315'
					src='https://www.youtube.com/embed/DyblJMsRFEM?si=UM29YOIWAyii-Qtj'
					title='Lilly interview'
					frameBorder='0'
					allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share'
					allowFullScreen></iframe>
			</div>
		</div>
	);
}
