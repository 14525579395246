import React from "react";
import "./IleGrouLeJeu.scss";

export default function IleGrouLeJeu() {
	return (
		<div id='ilegroulejeu' className='small_island'>
			<div className='video_container'>
				<video autoPlay muted loop playsInline>
					<source
						src='/videos/iles/grou_le_jeu-hevc-safari.mp4'
						type='video/quicktime'></source>
					<source src='/videos/iles/grou_le_jeu_VP9.webm' type='video/webm'></source>
				</video>
				{/* <img className='cover' src='/img/iles/groulejeu.webp' alt='' /> */}
			</div>
			<div className='text'>
				<h2 className='island_title'>
					Grou
					<span className='breakDown'>le jeu</span>
				</h2>
				<p>Grou en 8bit et en 2D? <br/>Talatopé !</p>
			</div>
		</div>
	);
}
