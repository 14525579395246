import React from "react";

export default function LilyQuestions() {
	return (
		<div>
			<p className='description'>
				<span className='oliver'>
					Et vous ? Qu'en pensez-vous ? <br />
				</span>
				Voici quelques questions pour entrer en discussion...
			</p>
			<ul className='questions-list'>
				<li>Qu’en penses-tu ?</li>
				<li>Est-ce que tu comprends pourquoi elle a fait ça ? </li>
				<li>Est-ce qu’elle a eu raison ?</li>
				<li>Comment cela peut-être un « bon souvenir » ?</li>
				<li>Comment la violence de Lily évolue-t-elle au fil du spectacle ?</li>
			</ul>
		</div>
	);
}
