import React from "react";
import "./IleAvantLeSpectacle.scss";
export default function IleAvantLeSpectacle({ OnClick }) {
	return (
		<>
			<div onClick={OnClick} className='Island' id='ileavantpectacle'>
				<div className='video_container'>
					<video autoPlay muted loop playsInline>
						<source
							src='/videos/iles/ile_avant_le_spectacle-hevc-safari.mp4'
							type='video/quicktime'></source>
						<source
							src='/videos/iles/ile_avant_le_spectacle-vp9-chrome.webm'
							type='video/webm'></source>
						<source
							src='/videos/iles/ile_avant_le_spectacle-hevc-safari.mp4'
							type='video/mp4'></source>
					</video>
				</div>
				<div className='text'>
					<h2 className='island_title'>
						<span className='breakDown'>Avant le</span>
						spectacle
					</h2>
					<p>Tu vas venir nous voir au théâtre ? On a deux mots à te dire !"</p>
				</div>
			</div>
		</>
	);
}
