import React from "react";
import { Link } from "react-router-dom";
import "./ModalAvantLeSpectacle.scss";
import VideoModule from "../../Pages/PagesComponents/AvantSpectacle/VideoSpectacle";

export default function ModalAvantLeSpectacle() {
	return (
		<div id='modalavantlespectacle' className='modal__wrapper modal__wrapper--blue'>
			<h4>
				<span className='smaller'>
					Avant le
					<br />
				</span>
				Spectacle
			</h4>
			<div className='part1'>
				<p className='text'>
					Venir au théâtre c’est pas comme tous les jours,{" "}
					<span className='oliver'>c’est un événement</span>, on sort de nos habitudes.
					c’est chouette et<span className='oliver'> c’est unique</span>. On a écrit ici{" "}
					<span className='oliver'> quelques trucs qu’on aime bien</span> quand on vous
					rencontre.
				</p>
				<Link to='/avant-le-spectacle' className='ball_cta'>
					<p className='oliver'>
						c'est <br />
						parti
					</p>
				</Link>
				<VideoModule
					mp4Url='/videos/arrows/Arrow_Element_09_Color_H.265.mp4'
					webmUrl='/videos/arrows/Arrow_Element_09_Color_VP9.webm'
					loop={true}
				/>
			</div>
			<div className='part2'>
				<VideoModule
					mp4Url='/videos/modals/renard-hevc-safari.mp4'
					webmUrl='/videos/modals/renard_VP9.webm'
					loop={true}
				/>
				<div className='texte'>
					<p className='oliver'>Vous êtes prêt.e.s à nous rejoindre ? </p>
					<p>Vérifiez en répondant aux questions !</p>
					<Link to='/quizz' className='cta--blue'>
						Le quizz
					</Link>
				</div>
			</div>
		</div>
	);
}
