import React, { useContext } from "react";
import QuizzContext from "../../../../QuizzContext";
import VideoModule from "../../AvantSpectacle/VideoSpectacle";
import "./Question7.scss";

export default function Question7() {
	const { handleAnswer } = useContext(QuizzContext);
	return (
		<div id='question7' className="question__wrapper">
			<h2 className='la_question'>
				Quelle est la recette du bon spectateur ou de la bonne spectatrice ?
			</h2>

			<div className='answers'>
				<VideoModule
					webmUrl='/videos/quizz/22_anim_avant_spectacle_VP9.webm'
					mp4Url='/videos/quizz/22_anim_avant_spectacle.mp4'
					loop={true}
				/>
				<div className='btns'>
					<button className='answer' onClick={() => handleAnswer(false)}>
						<span className='letter_choice'>A</span>
						<ul>
							<li>4 oeufs</li>
							<li>250g de farine</li>
							<li>60cl de lait</li>
						</ul>
					</button>

					<button className='answer' onClick={() => handleAnswer(false)}>
						<span className='letter_choice'>B</span>
						<ul>
							<li>Semoule</li>
							<li>Merguez</li>
							<li>Pois-chiches</li>
						</ul>
					</button>

					<button className='answer' onClick={() => handleAnswer(false)}>
						<span className='letter_choice'>C</span>
						<ul>
							<li>Téléphone</li>
							<li>Nourriture</li>
							<li>Galipettes</li>
						</ul>
					</button>

					<button className='answer' onClick={() => handleAnswer(true)}>
						<span className='letter_choice'>D</span>
						<ul>
							<li>Calme</li>
							<li>Curiosité</li>
							<li>Réactivité</li>
						</ul>
					</button>
				</div>

				{/* <VideoModule
						webmUrl='/videos/quizz/29_anim_avant_spectacle_VP9.webm'
						mp4Url='/videos/quizz/29_anim_avant_spectacle.mp4'
						loop={true}
					/> */}
			</div>
		</div>
	);
}
