import React, { useRef, useContext, useEffect } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Pagination, Keyboard, Mousewheel } from "swiper";
// Import Swiper styles | Production |
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import ModalContext from "../ModalContext";
import Nav from "../components/Layout/Nav";
import IleFoxes from "../Templates/Islands/IleFoxes";
import IleAvantLeSpectacle from "../Templates/Islands/IleAvantLeSpectacle.jsx";
import IleCourriers from "../Templates/Islands/IleCourriers";
import IleTadam from "../Templates/Islands/IleTadam";
import IleGrouLeJeu from "../Templates/Islands/IleGrouLeJeu";
import IleTheatreJeunePublic from "../Templates/Islands/IleTheatreJeunePublic";

import ModalFoxesChoices from "../Templates/ModalTemplates/ModalFoxesChoices";
import ModalAvantLeSpectacle from "../Templates/ModalTemplates/ModalAvantLeSpectacle";
import ModalCourrier from "../Templates/ModalTemplates/ModalCourrier";
import ModalJeunePublic from "../Templates/ModalTemplates/ModalJeunePublic";
import ModalGrouLeJeu from "../Templates/ModalTemplates/ModalGrouLeJeu";
import "./HomeIslands.scss";

import "./TestPage.scss";

// import required modules

export default function testPage() {
	return (
		<div className='testtt'>
			<h1>hello</h1>
			<iframe
				title='salut'
				src='https://lichess.org/training/frame?theme=brown&bg=dark'
				style={{ width: "400px", aspectRatio: "10/11;" }}
				allowtransparency='true'></iframe>
			<iframe src='https://fritz.chessbase.com' height='900px' width='900px'></iframe>
			{/* <div className='marker'></div> */}
			<div className='testcrop'>
				<iframe src='https://fritz.chessbase.com' height='900px' width='900px'></iframe>
			</div>
		</div>
	);
}
