import React, { useContext } from "react";
import "./ModalSoon.scss";
import "./ModalFoxesTemplate.scss";
import ModalContext from "../../../ModalContext";

import baptistePopup from "../../../assets/img/foxes/persos/baptiste_popup.png";

export default function ModalRenard() {
	const { closeModal } = useContext(ModalContext);

	return (
		<div id='modalSoon' className='modal__wrapper modal__wrapper--foxes-template'>
			<div className='modal__content__close' onClick={closeModal}></div>
			<div className='foxes-content-template'>
				<h2>Bientôt</h2>
				<h3>Patience, aventuriers curieux !</h3>
				<p>
					La magie est en route, et bientôt, notre équipe de renards espiègles vous
					dévoilera ce contenu pétillant.
				</p>
			</div>
		</div>
	);
}
