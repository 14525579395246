import React, { useContext } from "react";
import QuizzContext from "../../../QuizzContext";
import Question1 from "./questions/Question1";
import Question2 from "./questions/Question2";
import Question3 from "./questions/Question3";
import Question4 from "./questions/Question4";
import Question5 from "./questions/Question5";
import Question6 from "./questions/Question6";
import Question7 from "./questions/Question7";
import Reponse1 from "./questions/Reponse1";
import Reponse2 from "./questions/Reponse2";
import Reponse3 from "./questions/Reponse3";
import Reponse4 from "./questions/Reponse4";
import Reponse5 from "./questions/Reponse5";
import Reponse6 from "./questions/Reponse6";
import Reponse7 from "./questions/Reponse7";

import "./QuizzGame.scss";

export default function Questions() {
	const { score, currentQuestionIndex, userAnswered, hadCorrectAnswer, switchQuestion } =
		useContext(QuizzContext);

	return (
		<div className='quizz_container'>
			<div className={`question ${userAnswered ? "hide_question" : "show_question"}`}>
				<div className='informations'>
					<p className='question_count'> Question {currentQuestionIndex} </p>
					<figure className='score'> Score : {score} </figure>
				</div>

				{currentQuestionIndex === 1 && <Question1 />}
				{currentQuestionIndex === 2 && <Question2 />}
				{currentQuestionIndex === 3 && <Question3 />}
				{currentQuestionIndex === 4 && <Question4 />}
				{currentQuestionIndex === 5 && <Question5 />}
				{currentQuestionIndex === 6 && <Question6 />}
				{currentQuestionIndex === 7 && <Question7 />}
			</div>
			<div
				className={`reponse ${userAnswered ? "show_reponse" : "hide_reponse"} ${
					hadCorrectAnswer ? "correct_answer" : "wrong_answer"
				}
			`}>
				{currentQuestionIndex === 1 && <Reponse1 />}
				{currentQuestionIndex === 2 && <Reponse2 />}
				{currentQuestionIndex === 3 && <Reponse3 />}
				{currentQuestionIndex === 4 && <Reponse4 />}
				{currentQuestionIndex === 5 && <Reponse5 />}
				{currentQuestionIndex === 6 && <Reponse6 />}
				{currentQuestionIndex === 7 && <Reponse7 />}
				<button className='cta--white' onClick={switchQuestion}>
					{currentQuestionIndex < 7 && 'Question suivante'}
					{currentQuestionIndex >= 7 && 'Résultats'}
				</button>
			</div>
		</div>
	);
}
