import React from "react";
import "./IleTheatreJeunePublic.scss";
export default function IleTheatreJeunePublic({ OnClick }) {
	return (
		<>
			<div onClick={OnClick} className='small_island' id='ilediscussionjeunepublic'>
				<div className='video_container'>
					<video autoPlay muted loop playsInline>
						<source
							src='/videos/iles/pourquoi_theatre_jeune_public-hevc-safari.mp4'
							type='video/mp4;codecs=hvc1'></source>
						<source
							src='/videos/iles/pourquoi_theatre_jeune_public-vp9-chrome.webm'
							type='video/webm'></source>
					</video>
				</div>
				<div className='text'>
					<h2 className='island_title'>
						<span className='breakDown'>Theatre </span>Jeune Public
					</h2>
					<p>Pourquoi vous faites ça ?</p>
				</div>
			</div>
		</>
	);
}
