import React from "react";

export default function LilyQuestions() {
	return (
		<div>
			<p className='description'>
				<span className='oliver'>
					Et vous ? Qu'en pensez-vous ? <br />
				</span>
				Voici quelques questions pour entrer en discussion...
			</p>
			<ul className='questions-list'>
				<li>Selon vous, pourquoi Hervé fonctionne-t-il comme cela?</li>
				<li>Pourquoi Lily est-elle fâchée contre Hervé?</li>
				<li>
					Comment les outils technologiques créent-ils une barrière ou bien au contraire
					un lien avec le monde extérieur?
				</li>
			</ul>
		</div>
	);
}
