import React, { useContext } from "react";
import "./ModalProsper.scss";
import "./ModalFoxesTemplate.scss";
import ModalContext from "../../../ModalContext";
import ProsperContexte from "../../PageTemplates/Foxes/Prosper/ProsperContexte";
import ProsperInterview from "../../PageTemplates/Foxes/Prosper/ProsperInterview";
import ProsperQuestions from "../../PageTemplates/Foxes/Prosper/ProsperQuestions";
import ContentTemplate from "../../PageTemplates/Foxes/ContentTemplate";

import baptistePopup from "../../../assets/img/foxes/persos/baptiste_popup.png";

export default function ModalBaptiste() {
	const { closeModal } = useContext(ModalContext);

	return (
		<div id='modalBaptiste' className='modal__wrapper modal__wrapper--foxes-template'>
			<ContentTemplate
				title='Prosper'
				subtitle="« Ici, c'est ma cabane ! »"
				characterPicture={baptistePopup}
				templateContexte={<ProsperContexte />}
				templateQuestions={<ProsperQuestions />}
				templateInterview={<ProsperInterview />}
			/>
			<div className='modal__content__close' onClick={closeModal}></div>
		</div>
	);
}
