import React, { useContext } from "react";
import QuizzContext from "../../../../QuizzContext";
import "./Question2.scss";

export default function Question2() {
	const { handleAnswer } = useContext(QuizzContext);

	return (
		<div id='question2'>
			<h2 className='la_question'>
				Le spectacle a commencé...
				<br /> Que choisis-tu pour grignoter ?
			</h2>

			<div className='answers'>
				<button className='answer' onClick={() => handleAnswer(false)}>
					du Pop-corn
				</button>
				<button className='answer' onClick={() => handleAnswer(false)}>
					des bonbons
				</button>
				<button className='answer' onClick={() => handleAnswer(false)}>
					Un soda
				</button>
				<button className='answer' onClick={() => handleAnswer(true)}>
					Rien
				</button>
				<button className='answer' onClick={() => handleAnswer(false)}>
					Du chocolat
				</button>
				<button className='answer' onClick={() => handleAnswer(false)}>
					Des Brocolis
				</button>
				<button className='answer' onClick={() => handleAnswer(false)}>
					Du poulet rôti ?
				</button>
			</div>
		</div>
	);
}
