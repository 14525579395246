import React, { createContext, useState } from "react";
import { useNavigate } from "react-router-dom";

const QuizzContext = createContext();

export function QuizzProvider({ children }) {
	const [score, setScore] = useState(0);
	const [currentQuestionIndex, setCurrentQuestionIndex] = useState(1);
	const [userAnswered, setUserAnswered] = useState(false);
	const [hadCorrectAnswer, setHadCorrectAnswer] = useState(null);
	const navigate = useNavigate();

	const resetQuizz = (a) => {
		// console.log("yoreset le quizz");
		setCurrentQuestionIndex(1);
		setScore(0);
		setUserAnswered(false);
		setHadCorrectAnswer(false);
	};
	const switchQuestion = () => {
		if (currentQuestionIndex < 7) {
			setCurrentQuestionIndex(currentQuestionIndex + 1);
			setHadCorrectAnswer(null);
			setUserAnswered(false);
			console.log("ici");
		} else {
			navigate("/quizz/resultat");
			console.log("la");
		}
	};
	const checkTheInputsAnswers = (inputs) => {
		const checkedRightAnswers = Object.values(inputs.current.querySelectorAll(".ga input")).filter(
			(answer) => answer.checked
		);

		const checkedWrongAnswers = Object.values(inputs.current.querySelectorAll(".wa input")).filter(
			(answer) => answer.checked
		);
		const hasWrongAnswers = checkedWrongAnswers.length > 0 ? true : false;

		const hasRightAnswers =
			checkedRightAnswers.length ===
			Object.values(inputs.current.querySelectorAll(".ga input")).length
				? true
				: false;
		if (hasRightAnswers && !hasWrongAnswers) {
			handleAnswer(true);
		} else {
			handleAnswer(false);
		}
	};
	const handleAnswer = (answer) => {
		if (answer === true) {
			setHadCorrectAnswer(true);
			setScore(score + 1);
		}
		setUserAnswered(true);
		if (answer === false) {
			setHadCorrectAnswer(false);
		}
	};
	return (
		<QuizzContext.Provider
			value={{
				score,
				setScore,
				userAnswered,
				hadCorrectAnswer,
				currentQuestionIndex,
				handleAnswer,
				switchQuestion,
				resetQuizz,
				checkTheInputsAnswers,
			}}>
			{children}
		</QuizzContext.Provider>
	);
}
export default QuizzContext;
