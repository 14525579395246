import React, { useContext, useRef, useState, useEffect } from "react";

import "./ModalCassette.scss";
import ModalContext from "../../../ModalContext";
import Plyr from "plyr-react";
import "plyr-react/plyr.css";
import septemberAudioFile from "../../../assets/music/earth_wind_fire_september_72.mp3"; // importing the music
import amourAudioFile from "../../../assets/music/le_temps_de_lamour.mp3"; // importing the music
import supermanAudioFile from "../../../assets/music/o_superman_170621.mp3"; // importing the music
// import MusicPlayer from "../../PagesComponents/Foxes/MusicPlayer";

import skeud from "../../../assets/img/foxes/player/skeud.png";
import websiteIcon from "../../../assets/img/foxes/player/website-icon.svg";
import blurryBackground from "../../../assets/img/foxes/player/blurred_abstract_backgrounds_05.jpg";

export default function ModalAffiche({ findObject, findEffectRef, e }) {
	const { closeModal } = useContext(ModalContext);
	const plyrRef = useRef();
	const traskList = [
		{
			title: "Le temps de l'amour",
			artist: "Françoise Hardy — reprise par Guillaume Vésin",
			src: amourAudioFile,
			type: "audio/mp3",
		},
		{
			title: "O Superman",
			artist: "Laurie Anderson — reprise par Guillaume Vésin",
			src: supermanAudioFile,
			type: "audio/mp3",
		},
	];
	const [currentTrackIndex, setCurrentTrackIndex] = useState(0);

	const plyrProps = {
		source: {
			type: "audio",
			autoplay: true,
			sources: [traskList[currentTrackIndex]],
		},
	};
	function playNextTrack() {
		console.log("next track, au moment du calcul", currentTrackIndex);

		if (currentTrackIndex < traskList.length - 1) {
			let newIndex = currentTrackIndex + 1;
			setCurrentTrackIndex(newIndex);
		} else {
			setCurrentTrackIndex(0);
		}
	}
	function playPreviousTrack() {
		console.log("previous track", currentTrackIndex);

		if (currentTrackIndex > 0) {
			setCurrentTrackIndex(currentTrackIndex - 1);
		} else {
			setCurrentTrackIndex(traskList.length - 1);
		}
	}

	useEffect(() => {
		// console.log("internal plyr instance:", plyrRef.current.plyr);
		// console.log("currentTranckIndex", currentTrackIndex);
	});

	return (
		<div id='modalcassette' className='modal__wrapper modal__wrapper--cassette'>
			<div
				className='modal__content__close'
				onClick={() => {
					closeModal();
					findObject("cassette", e, findEffectRef);
				}}></div>
			<img className='blurry-background' src={blurryBackground} alt='' />
			<div className='content'>
				<div className='audio-player'>
					<h2 className='audio-player__title'>
						Le jukebox <br /> de Guiguiz
					</h2>

					<img className='skeud' src={skeud} alt='' />

					<div className='audio-player__current-infos'>
						<p className='audio-player__current-track-index'>
							{currentTrackIndex + 1} / {traskList.length}
						</p>
						<p className='audio-player__current-track-name'>
							{traskList[currentTrackIndex].title}
						</p>
						<p className='audio-player__current-artist'>
							{traskList[currentTrackIndex].artist}
						</p>
					</div>
					<div className='audio-player__prevnext-buttons'>
						<button className='previous-button' onClick={playPreviousTrack}>
							Précédent
						</button>
						<button className='next-button' onClick={playNextTrack}>
							Suivant
						</button>
					</div>
					<Plyr ref={plyrRef} {...plyrProps} />
					<p className='audio-player__author-infos'>
						<img className='website-icon' src={websiteIcon} alt='' />
						<a
							target='_blank'
							rel='noreferrer'
							href='https://guillaumevesin.wixsite.com/guillaumevesin'>
							Guillaume Vésin
						</a>
					</p>
				</div>
			</div>
		</div>
	);
}
