import React from "react";

export default function ResultSuper() {
	return (
		<>
			<h4 className='resultat__commentaire_title'>Super !</h4>
			<p className='resultat__commentaire_description'>Bien joué. Vivement le spectacle. Ça va être bien !</p>
		</>
	);
}
