import React, { useContext, useRef } from "react";
import QuizzContext from "../../../../QuizzContext";
import "./Question5.scss";

export default function Question5() {
	const { checkTheInputsAnswers } = useContext(QuizzContext);
	const inputs = useRef(null);

	return (
		<div id='question5'>
			<h3>Vrai ou faux ? </h3>
			<h2 className='la_question'>
				« Les fauteuils de théâtre ont été conçus pour servir de trampolines »
			</h2>

			<div className='answers'>
				<ul ref={inputs}>
					<li className='wa'>
						<input type='checkbox' name='agreement' />
						<label htmlFor='agreement'>Vrai</label>
					</li>
					<li className='ga'>
						<input type='checkbox' name='agreement' />
						<label htmlFor='agreement'>Faux</label>
					</li>
				</ul>

				{/* <VideoModule
						webmUrl='/videos/quizz/29_anim_avant_spectacle_VP9.webm'
						mp4Url='/videos/quizz/29_anim_avant_spectacle.mp4'
						loop={true}
					/> */}
				<button className='answer cta_orangered' onClick={() => checkTheInputsAnswers(inputs)}>
					Vérifier ma réponse
				</button>
			</div>
		</div>
	);
}
