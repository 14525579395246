import React from "react";

export default function ResultPasMal() {
	return (
		<div id='resultat_pasmal'>
			<h4 className='resultat__commentaire_title'>Pas mal !</h4>
			<p className='resultat__commentaire_description'>
				Ok, c’est pas mal. Jette quand même un coup d’oeil aux bonnes réponses avant de venir ;)
			</p>
		</div>
	);
}
