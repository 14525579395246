import React from "react";
import "./Toolbar.scss";
import ToolbarObject from "./ToolbarObject";

import journalLight from "../../../assets/img/foxes/objects/journal_light.png";
import miroLight from "../../../assets/img/foxes/objects/micro_light.png";
import droneLight from "../../../assets/img/foxes/objects/drone_light.png";
import casqueLight from "../../../assets/img/foxes/objects/casque_light.png";
import pianoLight from "../../../assets/img/foxes/objects/piano_light.png";
import gantsLight from "../../../assets/img/foxes/objects/gant_light.png";
import cassetteLight from "../../../assets/img/foxes/objects/cassette_light.png";
import afficheLight from "../../../assets/img/foxes/objects/affiche_light.png";

export default function Toolbar({ foundObjects }) {
	return (
		<div className='toolbar'>
			<div className='toolbar__container'>
				<div className='toolbar__objects'>
					<ToolbarObject
						found={foundObjects.journal ? "true" : "false"}
						src={journalLight}
					/>
					<ToolbarObject
						found={foundObjects.cassette ? "true" : "false"}
						src={cassetteLight}
					/>
					<ToolbarObject found={foundObjects.drone ? "true" : "false"} src={droneLight} />
					<ToolbarObject
						found={foundObjects.casque ? "true" : "false"}
						src={casqueLight}
					/>
					<ToolbarObject found={foundObjects.piano ? "true" : "false"} src={pianoLight} />
					<ToolbarObject found={foundObjects.gants ? "true" : "false"} src={gantsLight} />

					<ToolbarObject found={foundObjects.micro ? "true" : "false"} src={miroLight} />
					<ToolbarObject
						found={foundObjects.affiche ? "true" : "false"}
						src={afficheLight}
					/>
				</div>
			</div>
		</div>
	);
}
