import React from "react";

export default function Contexte() {
	return (
		<>
			{/* <h4 className='title'>Lily Context</h4> */}
			<p className='description'>
				<span className='oliver'>Hervé</span> n'est pas très à l'aise pour communiquer avec les
				autres...
			</p>
			<div className='video_container'>
				<iframe
					width='560'
					height='315'
					src='https://www.youtube.com/embed/1dTC-xfVT2w?si=rbTa_DHCTaeq_uH7'
					title='YouTube video player'
					frameBorder='0'
					allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share'
					allowFullScreen></iframe>
			</div>
		</>
	);
}
