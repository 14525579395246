import React from "react";

export default function BambiQuestions() {
	return (
		<div>
			<p className='description'>
				<span className='oliver'>
					Et vous ? Qu'en pensez-vous ? <br />
				</span>
				Voici quelques questions pour entrer en discussion...
			</p>
			<ul className='questions-list'>
				<li>Pourquoi dit-elle cela?</li>
				<li>Comment La Voix Junior peut-être à la fois un rêve et un cauchemar pour Bambi? </li>
				<li>Comment Bambi parvient-elle à combattre sa détresse?</li>
			</ul>
		</div>
	);
}
