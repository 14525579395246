import React from "react";
import VideoModule from "../../AvantSpectacle/VideoSpectacle";
import "./Reponse3.scss";

export default function Reponse2() {
	return (
		<div id='reponse3' className="reponse__wrapper">
			<h5 className='reponse__tag'>Réponse</h5>
			<VideoModule
				mp4Url='/videos/quizz/aplauss3-hevc-safari.mp4'
				webmUrl='/videos/quizz/aplauss3-vp9-chrome.webm'
				loop={true}
			/>
			<h4 className='reponse__title'>À la fin, dans tes mains !</h4>
			<h6 className='reponse__subtitle'>
				(et tu peux aussi crier: « Bravo! » ou « incroyable! » ou « je vous aime !»)
			</h6>
			<p className='reponse__detail'>
				Les applaudissements c’est la récompense des artistes, ça fait vraiment plaisir, alors
				si le spectacle t’a plu, frappe fort et longtemps !
			</p>
		</div>
	);
}
