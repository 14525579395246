import React, { useState, useContext, useEffect } from "react";
import "./ContentTemplate.scss";
import backgroundImageLight from "../../../assets/img/foxes/foxe_scene_light_2000x1125empty.webp";
import ModalContext from "../../../ModalContext";
export default function ContentTemplate({
	title,
	subtitle,
	characterPicture,
	templateContexte,
	templateQuestions,
	templateInterview,
}) {
	const [currentTab, setCurrentTab] = useState("contexte");

	function handleCurrentTabOnClick(tab) {
		setCurrentTab(tab);
	}
	const { closeModal } = useContext(ModalContext);

	useEffect(() => {
		let modal = document.querySelector("#modal");
		modal.scrollTo({ top: 0, behavior: "smooth" });
	}, [currentTab]);

	return (
		<>
			<div className='foxes-titling'>
				<h3 className='title'> {title}</h3>
				<h5 className='subtitle'>{subtitle}</h5>
			</div>
			<ul className='template-internal-navigation'>
				<li
					className={`template-internal-navigation__link ${
						currentTab === "contexte"
							? "template-internal-navigation__link-active"
							: ""
					}`}
					onClick={() => handleCurrentTabOnClick("contexte")}>
					Contexte
				</li>
				<li
					className={`template-internal-navigation__link ${
						currentTab === "questions"
							? "template-internal-navigation__link-active"
							: ""
					}`}
					onClick={() => handleCurrentTabOnClick("questions")}>
					Questions
				</li>
				<li
					className={`template-internal-navigation__link ${
						currentTab === "interview"
							? "template-internal-navigation__link-active"
							: ""
					}`}
					onClick={() => handleCurrentTabOnClick("interview")}>
					Interview
				</li>
			</ul>

			<div className='foxes-content-template'>
				{currentTab === "contexte" && (
					<>
						<div className='character_picture'>
							{/* <img className='backgroundDark' src={backgroundImageDark} alt='' /> */}
							<img className='character' src={characterPicture} alt='' />
							<div className='background_lampe'>
								<img
									className='lampe'
									src={backgroundImageLight}
									alt=''
								/>
							</div>
						</div>
						<div className='foxes-content-template__content foxes-content-template__content--contexte'>
							{templateContexte}
						</div>
						<button
							className='cta-arrowed cta-arrowed--orangered'
							onClick={() => {
								handleCurrentTabOnClick("questions");
							}}>
							Vers les Questions
						</button>
					</>
				)}
				{currentTab === "questions" && (
					<>
						<div className='foxes-content-template__content foxes-content-template__content--questions'>
							{templateQuestions}
						</div>
						<button
							className='cta-arrowed cta-arrowed--orangered'
							onClick={() => handleCurrentTabOnClick("interview")}>
							Vers l'Interview
						</button>
					</>
				)}
				{currentTab === "interview" && (
					<>
						<div className='foxes-content-template__content foxes-content-template__content--interview'>
							{templateInterview}
						</div>
						<button
							className='cta-arrowed cta-arrowed--orangered'
							onClick={closeModal}>
							Retour au jeu
						</button>
					</>
				)}
			</div>
		</>
	);
}
