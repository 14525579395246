import React from "react";

export default function LilyInterview() {
	return (
		<div>
			<p className='description'>
				On donne la parole à Adrien Desbons
				<br />
				<span className='oliver'>« C'est qui, pour toi, Hervé ??? »</span>
			</p>
			<div className='video_container'>
				<iframe
					width='560'
					height='315'
					src='https://www.youtube.com/embed/gwT_8k0edMs?si=UF6z89T1ZdjISruX'
					title='YouTube video player'
					frameBorder='0'
					allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share'
					allowFullScreen></iframe>
			</div>
		</div>
	);
}
