import React from "react";
import VideoModule from "../../AvantSpectacle/VideoSpectacle";
import "./Reponse6.scss";

export default function Reponse6() {
	return (
		<div id='reponse6' className='reponse__wrapper'>
			<h5 className='reponse__tag'>Réponse</h5>
			<h4 className='reponse__title reponse__title--checkbox'>
				<input type='checkbox' checked name='' id='' />
				<label htmlFor=''>Toutes !</label>
			</h4>

			<VideoModule
				mp4Url='/videos/quizz/welcome-hevc-safari.mp4'
				webmUrl='/videos/quizz/welcome-vp9-chrome.webm'
				loop={false}
			/>
			<p className='reponse__detail'>
				Le théâtre n’est pas le lieu de la leçon mais celui de l’émotion. Tu peux y vivre toute
				sortes d’émotions en toute sécurité et c’est passionnant de voir que ton voisin ou ta
				voisine, ne vivra pas forcément les mêmes émotions que toi !
			</p>
		</div>
	);
}
