import React, { useContext } from "react";
import "./ModalLily.scss";
import "./ModalFoxesTemplate.scss";
import ModalContext from "../../../ModalContext";
import LilyContexte from "../../PageTemplates/Foxes/Lily/LilyContexte";
import LilyInterview from "../../PageTemplates/Foxes/Lily/LilyInterview";
import LilyQuestions from "../../PageTemplates/Foxes/Lily/LilyQuestions";
import ContentTemplate from "../../PageTemplates/Foxes/ContentTemplate";

import lilyPopup from "../../../assets/img/foxes/persos/lily_popup.png";
export default function ModalLily() {
	const { closeModal } = useContext(ModalContext);

	return (
		<div id='modallily' className='modal__wrapper modal__wrapper--foxes-template'>
			{/* <TemplateInternalNavigation /> */}
			<ContentTemplate
				title='Lily'
				subtitle="« À 10 ans, j'ai pété la gueule d'un gars »"
				characterPicture={lilyPopup}
				templateContexte={<LilyContexte />}
				templateQuestions={<LilyQuestions />}
				templateInterview={<LilyInterview />}
			/>
			<div className='modal__content__close' onClick={closeModal}></div>
		</div>
	);
}
