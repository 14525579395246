import React from "react";
import "./Reponse5.scss";

export default function Reponse5() {
	return (
		<div id='reponse5' className="reponse__wrapper">
			<h5 className='reponse__tag'>Réponse</h5>
			<h4 className='reponse__title reponse__title--checkbox'>
				<input type='checkbox' checked name='' id='' />
				<label htmlFor="">Faux !</label>
				
			</h4>
			<p className='reponse__detail'>( Tellement dommage, on est d'accord )</p>
		</div>
	);
}
