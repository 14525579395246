import React from "react";
import { Link } from "react-router-dom";
import VideoModule from "../../Pages/PagesComponents/AvantSpectacle/VideoSpectacle";
import "./ModalCourrier.scss";

import Lottie from "lottie-react";
import arrow from "../../assets/lottie/arrow34.json";
import coeur from "../../assets/img/courriers/coeur.png";

export default function ModalCourrier() {
	return (
		<div id='modalcourrier' className='modal__wrapper modal__wrapper--blue'>
			<div className='text'>
				<h4>
					Courrier du
					<img className='heartPicture' src={coeur} alt='' />
				</h4>

				<p>
					Nos spectateurs nous ont envoyé
					<span className='oliver'> des centaines de cartes postales…</span>
				</p>
				<p>
					Tu veux en voir <span className='oliver'>quelques unes ?</span>
				</p>
				<div className='goget'>
					<Lottie className='arrow' animationData={arrow} loop autoplay />

					<Link to='/courriers-du-coeur' className='cta_orangered'>
						c'est par ici
					</Link>
					<Lottie className='arrow' animationData={arrow} loop autoplay />
				</div>
			</div>

			<VideoModule
				webmUrl='/videos/modals/coeur_VP9.webm'
				mp4Url='/videos/modals/coeur-hevc-safari.mp4'
				loop={true}
			/>
		</div>
	);
}
