import React, { useContext } from "react";
import QuizzContext from "../../../../QuizzContext";
import VideoModule from "../../AvantSpectacle/VideoSpectacle";
import "./Question1.scss";
import cerveau from "../../../../assets/img/quizz/cerveau_fixe0.png";

export default function Question1() {
	const { handleAnswer } = useContext(QuizzContext);

	return (
		<div id='question1'>
			<h2 className='la_question'>
				Le spectacle commence ! <br />
				Quel réglage choisis-tu pour…
				<br /> « l'Activité de ton cerveau » ?
			</h2>

			<div className='answers'>
				<button className='answer' onClick={() => handleAnswer(false)}>
					<img className='brain' src={cerveau} alt='' />
					<div className=''>
						<h4>Faible</h4>
						<p>Cerveau au repos</p>
					</div>
				</button>
				<button className='answer' onClick={() => handleAnswer(true)}>
					<VideoModule
						className='brain'
						mp4Url='/videos/quizz/cerveau_vibration-hevc-safari.mp4'
						webmUrl='/videos/quizz/cerveau_vibration-vp9-chrome.webm'
						loop={true}
					/>

					<div>
						<h4>Intense</h4>
						<p>( Cerveau connecté ! )</p>
					</div>
				</button>
			</div>
		</div>
	);
}
