import React from "react";
import "./IleCourriers.scss";
import coeur from "../../assets/img/courriers/coeur.png";
export default function IleCourriers() {
	return (
		<>
			<div id='ilecourriers' className='small_island'>
				<div className='video_container'>
					<video autoPlay muted loop playsInline>
						<source
							src='/videos/iles/courrier_du_coeur-hevc-safari.mp4'
							type='video/quicktime'></source>
						<source
							src='/videos/iles/courrier_du_coeur_VP9.webm'
							type='video/webm'></source>
					</video>
				</div>
				<div className='text'>
					<h2 className='island_title'>
						courrier
						<span className='breakDown'>
							du
							<img className='coeur' src={coeur} alt='' />
						</span>
					</h2>

					<p>Vos dessins et vos mots doux sont parvenus jusqu'à nous !</p>
				</div>
			</div>
		</>
	);
}
