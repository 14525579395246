import React from "react";
import "./Reponse7.scss";

export default function Reponse7() {
	return (
		<div id='reponse7' className="reponse__wrapper">
			<h5 className='reponse__tag'>Réponse</h5>

			<div className='recette_commentary'>
				<div className='recette_1'>
					<div className='proposition'>
						<p className='letter_choice'>A</p>
						<ul>
							<li>4 oeufs</li>
							<li>250g de farine</li>
							<li>60cl de lait</li>
						</ul>
					</div>
					<h6>
						Non !
						<br />
					</h6>

					<p className='commentaire'>C'est la recette des crêpes</p>
				</div>
				<div className='recette_2'>
					<div className='proposition'>
						<p className='letter_choice'>B</p>
						<ul>
							<li>Semoule</li>
							<li>Merguez</li>
							<li>Pois-chiches</li>
						</ul>
					</div>
					<h6>
						Non !
						<br />
					</h6>

					<p className='commentaire'>C'est la recette du couscous</p>
				</div>
				<div className='recette_3'>
					<p className='letter_choice'>C</p>
					<ul>
						<li>Téléphone</li>
						<li>Nourriture</li>
						<li>Galipettes</li>
					</ul>
					<h6>
						Non !
						<br />
					</h6>
					<p className='commentaire'> C'est la recette du n'importe quoi</p>
				</div>
				<div className='recette_4'>
					<div className='proposition'>
						<p className='letter_choice'>D</p>
						<ul>
							<li>Calme</li>
							<li>Curiosité</li>
							<li>Réactivité</li>
						</ul>
					</div>
					<h6>
						Oui !
						<br />
					</h6>
					<p className='commentaire'>C'est ça !</p>
				</div>
			</div>
			{/* 	
			<p className='reponse__detail'>
				Le théâtre n’est pas le lieu de la leçon mais celui de l’émotion. Tu peux y vivre toute
				sortes d’émotions en toute sécurité et c’est passionnant de voir que ton voisin ou ta
				voisine, ne vivra pas forcément les mêmes émotions que toi !
			</p> */}
		</div>
	);
}
